/* tslint:disable */
import typia from 'typia';
import { Profile } from '~/backend/gmx-api/profile/Profile';
export const validateProfile = (() => { const $guard = (typia.createAssert as any).guard; const $io0 = (input: any): boolean => "boolean" === typeof input.active && "string" === typeof input.email && "string" === typeof input.name && (Array.isArray(input.roles) && input.roles.every((elem: any) => "ADMIN" === elem || "CONTENT_MANAGER" === elem || "VIEWER" === elem || "GM_OPS" === elem || "DYNAMIC_CONFIG_MANAGER" === elem || "OFFER_MANAGER" === elem)); const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("boolean" === typeof input.active || $guard(_exceptionable, {
    path: _path + ".active",
    expected: "boolean",
    value: input.active
}, _errorFactory)) && ("string" === typeof input.email || $guard(_exceptionable, {
    path: _path + ".email",
    expected: "string",
    value: input.email
}, _errorFactory)) && ("string" === typeof input.name || $guard(_exceptionable, {
    path: _path + ".name",
    expected: "string",
    value: input.name
}, _errorFactory)) && ((Array.isArray(input.roles) || $guard(_exceptionable, {
    path: _path + ".roles",
    expected: "Array<\"ADMIN\" | \"CONTENT_MANAGER\" | \"VIEWER\" | \"GM_OPS\" | \"DYNAMIC_CONFIG_MANAGER\" | \"OFFER_MANAGER\">",
    value: input.roles
}, _errorFactory)) && input.roles.every((elem: any, _index2: number) => "ADMIN" === elem || "CONTENT_MANAGER" === elem || "VIEWER" === elem || "GM_OPS" === elem || "DYNAMIC_CONFIG_MANAGER" === elem || "OFFER_MANAGER" === elem || $guard(_exceptionable, {
    path: _path + ".roles[" + _index2 + "]",
    expected: "(\"ADMIN\" | \"CONTENT_MANAGER\" | \"DYNAMIC_CONFIG_MANAGER\" | \"GM_OPS\" | \"OFFER_MANAGER\" | \"VIEWER\")",
    value: elem
}, _errorFactory)) || $guard(_exceptionable, {
    path: _path + ".roles",
    expected: "Array<\"ADMIN\" | \"CONTENT_MANAGER\" | \"VIEWER\" | \"GM_OPS\" | \"DYNAMIC_CONFIG_MANAGER\" | \"OFFER_MANAGER\">",
    value: input.roles
}, _errorFactory)); const __is = (input: any): input is Profile => "object" === typeof input && null !== input && $io0(input); let _errorFactory: any; return (input: any, errorFactory?: (p: import("typia").TypeGuardError.IProps) => Error): Profile => {
    if (false === __is(input)) {
        _errorFactory = errorFactory;
        ((input: any, _path: string, _exceptionable: boolean = true) => ("object" === typeof input && null !== input || $guard(true, {
            path: _path + "",
            expected: "Profile",
            value: input
        }, _errorFactory)) && $ao0(input, _path + "", true) || $guard(true, {
            path: _path + "",
            expected: "Profile",
            value: input
        }, _errorFactory))(input, "$input", true);
    }
    return input;
}; })();
