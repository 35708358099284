/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import { captureException } from '@sentry/nextjs'
import axios, {
  type AxiosError,
  type AxiosPromise,
  type AxiosRequestConfig,
  type AxiosResponse,
} from 'axios'
import toast from 'react-hot-toast'

import { INTERNAL_ERRORS_ENABLED, SENTRY_ENABLED } from '~/config'

/**
 * @deprecated Use ~/backend/bo instead.
 */
function handleValidationError(error: unknown, requestConfig: AxiosRequestConfig) {
  const { baseURL, method, url } = requestConfig
  if (typeof window !== 'undefined') {
    if (INTERNAL_ERRORS_ENABLED) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      toast.error(`${method} ${url}: ${error}`)
    }
  }

  if (SENTRY_ENABLED) {
    captureException(error, { extra: { baseURL, method, url } })
  }

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  console.error(`${method} ${url}: ${error}`)
}

/**
 * @deprecated Use ~/backend/bo instead.
 */
interface GmxApiResponseData {
  data: unknown
  errors: { code: string }[] | null
}

/**
 * @deprecated Use ~/backend/bo instead.
 */
interface GmxApiResultSuccess<Data> {
  data: Data
  status: number
  type: 'success'
}

/**
 * @deprecated Use ~/backend/bo instead.
 */
export interface GmxApiResultClientError<ErrorCode extends string> {
  errors: { code: ErrorCode }[]
  status: number
  type: 'client error'
}

/**
 * @deprecated Use ~/backend/bo instead.
 */
export interface GmxApiResultServerError {
  error: AxiosError
  type: 'server error'
}
/**
 * @deprecated Use ~/backend/bo instead.
 */
export interface GmxApiResultNetworkError {
  error: AxiosError
  type: 'network error'
}
/**
 * @deprecated Use ~/backend/bo instead.
 */
export type GmxApiResult<Data, ErrorCode extends string> =
  | GmxApiResultSuccess<Data>
  | GmxApiResultClientError<ErrorCode>
  | GmxApiResultServerError
  | GmxApiResultNetworkError

/**
 * @deprecated Use ~/backend/bo instead.
 */
export async function deprecatedGmxApiRequest<
  ApiData = unknown,
  Data = ApiData,
  ErrorCode extends string = never,
>({
  errorCodes,
  request,
  transform,
  validate,
}: {
  errorCodes: Readonly<ErrorCode[]>
  request: () => AxiosPromise<GmxApiResponseData>
  transform?: (apiData: ApiData) => Data
  validate?: (value: unknown) => ApiData
}): Promise<GmxApiResult<Data, ErrorCode>> {
  let response
  try {
    response = await request()
  } catch (error: unknown) {
    if (!axios.isAxiosError(error)) {
      // Unhandled error. Most likely the request itself is broken.
      // Not something for us to handle here.
      throw error
    }
    return handleError(error, errorCodes)
  }

  const apiData: ApiData = handleValidation(response, validate)
  const data: Data = transform ? transform(apiData) : (apiData as unknown as Data)

  return {
    data,
    status: response.status,
    type: 'success',
  }
}

/**
 * @deprecated Use ~/backend/bo instead.
 */

function handleValidation<ApiData = unknown>(
  response: AxiosResponse<GmxApiResponseData>,
  validate?: (value: unknown) => ApiData,
): ApiData {
  const {
    data: { data },
  } = response
  if (!validate) {
    return data as ApiData
  }

  try {
    return validate(data)
  } catch (error) {
    handleValidationError(error, response.config)
    return data as ApiData
  }
}

/**
 * @deprecated Use ~/backend/bo instead.
 */
function handleError<ErrorCode extends string = never>(
  error: AxiosError,
  errorCodes: Readonly<ErrorCode[]>,
): GmxApiResultClientError<ErrorCode> | GmxApiResultServerError | GmxApiResultNetworkError {
  const { response } = error
  // If we don't get response, we've been unsuccessful in connecting to the backend.
  if (error.message === 'Network Error' || !response) {
    return {
      error,
      type: 'network error',
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
  const errors: unknown = (response.data as any)?.errors
  if (
    !Array.isArray(errors) ||
    errors.length === 0 ||
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    !errors.every((e): e is { code: string } => typeof e?.code === 'string')
  ) {
    // We received a response, but the backend is breaking protocol. Probably we've detected an issue (like a 500).
    return {
      error,
      type: 'server error',
    }
  }

  const typedErrors = (() => {
    errors.forEach(({ code }) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
      if (!errorCodes.includes(code as any)) {
        handleValidationError(
          new Error(
            `Received unexpected errorCode '${code}', expected one of ${errorCodes.toString()}`,
          ),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
          error.config as any,
        )
      }
    })
    return errors as { code: ErrorCode }[]
  })()

  return {
    errors: typedErrors,
    status: response.status,
    type: 'client error',
  }
}
