/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import {
  type QueryClient,
  useMutation,
  type UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { type QueryKey, useQuery, type UseQueryOptions } from '@tanstack/react-query'

import {
  type GmxApiResult,
  type GmxApiResultClientError,
  type GmxApiResultNetworkError,
  type GmxApiResultServerError,
} from '~/backend/gmx-api/core/request'

const useGmxApiQuery = <
  TParams,
  TData,
  TErrorCode extends string,
  TError extends
    | GmxApiResultClientError<TErrorCode>
    | GmxApiResultServerError
    | GmxApiResultNetworkError,
  TQueryData = TData,
  TQueryKey extends QueryKey = QueryKey,
>(
  params: TParams,
  request: (params: TParams) => Promise<GmxApiResult<TData, TErrorCode>>,
  queryKey: (params: TParams) => TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError, TQueryData, TQueryKey>, 'queryKey' | 'queryFn'>,
) =>
  useQuery<TData, TError, TQueryData, TQueryKey>({
    queryFn: async () => {
      const result = await request(params)
      if (result.type !== 'success') {
        throw result
      }
      return result.data
    },
    queryKey: queryKey(params),
    ...options,
  })

/**
 * @deprecated Use ~/backend/bo instead.
 */
export const deprecatedCreateGmxApiQueryHook =
  <
    TParams,
    TData,
    TErrorCode extends string,
    TError extends
      | GmxApiResultClientError<TErrorCode>
      | GmxApiResultServerError
      | GmxApiResultNetworkError,
    TQueryData = TData,
    TQueryKey extends QueryKey = QueryKey,
  >(
    queryKey: (params: TParams) => TQueryKey,
    request: (params: TParams) => Promise<GmxApiResult<TData, TErrorCode>>,
    defaultOptions?:
      | Omit<UseQueryOptions<TData, TError, TQueryData, TQueryKey>, 'queryKey' | 'queryFn'>
      | ((
          params: TParams,
        ) => Omit<UseQueryOptions<TData, TError, TQueryData, TQueryKey>, 'queryKey' | 'queryFn'>),
  ) =>
  (
    params: TParams,
    options?: Omit<UseQueryOptions<TData, TError, TQueryData, TQueryKey>, 'queryKey' | 'queryFn'>,
  ) => {
    let _defaultOptions
    if (typeof defaultOptions === 'function') {
      _defaultOptions = defaultOptions(params)
    } else {
      _defaultOptions = defaultOptions
    }
    return useGmxApiQuery(params, request, queryKey, { ..._defaultOptions, ...options })
  }

const useGmxApiMutation = <
  TVariables,
  TData,
  TErrorCode extends string,
  TError extends
    | GmxApiResultClientError<TErrorCode>
    | GmxApiResultServerError
    | GmxApiResultNetworkError,
  TContext = unknown,
>(
  request: (params: TVariables) => Promise<GmxApiResult<TData, TErrorCode>>,
  options?:
    | Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>
    | ((
        queryClient: QueryClient,
      ) => Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>),
) => {
  const queryClient = useQueryClient()
  const optionz = typeof options === 'function' ? options(queryClient) : options

  return useMutation<TData, TError, TVariables, TContext>({
    mutationFn: async (variables) => {
      const result = await request(variables)
      if (result.type !== 'success') {
        throw result
      }
      return result.data
    },
    ...optionz,
  })
}

/**
 * @deprecated Use ~/backend/bo instead.
 */
export const deprecatedCreateGmxApiMutationHook =
  <
    TVariables,
    TData,
    TErrorCode extends string,
    TError extends
      | GmxApiResultClientError<TErrorCode>
      | GmxApiResultServerError
      | GmxApiResultNetworkError,
    TContext = unknown,
  >(
    request: (params: TVariables) => Promise<GmxApiResult<TData, TErrorCode>>,
    /**
     * Set default options for the new hook or pass in a function to evaluate options
     * at runtime. The function can be used to call hooks. It also provides the queryClient
     * for convenience.
     *
     * Example as object:
     * ```typescript
     *
     * const useMyMutation = createGmxApiMutationHook(myRequest, {
     *   onError: (errors) => {
     *     if (errors.includes('something_real_bad')) {
     *       showToast('Oh nooo!')
     *     }
     *   },
     * })
     * ```
     *
     * Example as function:
     * ```typescript
     * const useMyMutation = createGmxApiMutationHook(myRequest, (queryClient) => ({
     *   onSettled: (data, error, variables) => queryClient.invalidateQueries({ queryKey: ['my query key'] }),
     * ))
     * ```
     */
    defaultOptions?:
      | Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>
      | ((
          queryClient: QueryClient,
        ) => Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>),
  ) =>
  (options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>) => {
    const queryClient = useQueryClient()
    const defaultOptionz =
      typeof defaultOptions === 'function' ? defaultOptions(queryClient) : defaultOptions

    return useGmxApiMutation(request, { ...defaultOptionz, ...options })
  }
