/*
 * Copyright © 2023 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...classes: ClassValue[]) {
  return twMerge(clsx(classes))
}
