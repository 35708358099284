/*
 * Copyright © 2021 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import { deprecatedCreateGmxApiQueryHook } from '~/backend/gmx-api/core/query'

import { getProfileRequest } from './profile.request'

const QUERY_KEY = 'bo.profile'

export const useProfile = deprecatedCreateGmxApiQueryHook(() => [QUERY_KEY], getProfileRequest)
