/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

/* eslint-disable react/forbid-elements */

'use client'

import { usePathname } from 'next/navigation'
import { type ElementRef, useEffect, useRef, useState } from 'react'

import { useProfile } from '~/backend/gmx-api/profile/Profile.query'
import { type Role } from '~/backend/gmx-api/roles/Roles'
import { deprecated_dont_use_Link as Link } from '~/components/Link'
import { cn } from '~/utils/cn'

export default function Nav({
  children,
  className,
  isTopLevel = false,
}: {
  children: { href: string; name: string; roles: readonly Role[] }[]
  className?: string
  isTopLevel?: boolean
}) {
  const ref = useRef<ElementRef<'ul'>>(null)
  function isOverflown(element: HTMLElement) {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth
  }

  const [show, setShow] = useState(false)
  const [itemsOnFirstRow, setItemsOnFirstRow] = useState(0)
  useEffect(() => {
    const curr = ref.current
    if (!curr) {
      return
    }

    const showIfOverflown = () => {
      setShow(isOverflown(curr))
    }
    const countItemsOnFirstRow = () => {
      const children = Array.from(curr.children)
      const offsetTop = children[0]?.children[0]?.getBoundingClientRect().top
      if (offsetTop === undefined) {
        return
      }
      const itemsOnFirstRow = children.reduce((count, curr) => {
        return curr.children[0]?.getBoundingClientRect().top === offsetTop ? count + 1 : count
      }, 0)

      setItemsOnFirstRow(itemsOnFirstRow)
    }
    const handler = () => {
      showIfOverflown()
      countItemsOnFirstRow()
    }

    // Note(joakimd): Observer makes sure that the menu is rendered correctly without having to do a resize first
    const observer = new MutationObserver(handler)
    observer.observe(curr, { childList: true })
    window.addEventListener('resize', handler)
    return () => {
      window.removeEventListener('resize', handler)
      observer.disconnect()
    }
  }, [])

  const availableRoles = useProfile({}).data?.roles ?? []
  const pathname = usePathname()

  return (
    <div className={cn('flex max-w-full items-center', className)}>
      <nav className="contents">
        <ul ref={ref} className="flex h-full flex-wrap overflow-hidden">
          {children.map(({ href, name, roles }) => {
            if (!roles.some((role) => availableRoles.includes(role))) {
              return null
            }

            const isActive = isLinkActive(href, pathname, isTopLevel)

            return (
              <li key={href} className="contents">
                <Link
                  className={cn('relative flex h-full items-center px-4 py-2', {
                    'after:absolute after:inset-x-0 after:bottom-0 after:mx-auto after:h-0.5 after:w-1/2 after:bg-neutral-100':
                      isActive,
                  })}
                  href={href}
                >
                  <span
                    className={cn('inline-block', {
                      '-translate-y-px scale-105 transition-all ease-out': isActive,
                    })}
                  >
                    {name}
                  </span>
                </Link>
              </li>
            )
          })}
        </ul>
      </nav>
      <div
        className={cn(
          'group relative grid h-7 w-14 shrink-0 grow-0 place-items-center rounded border hover:bg-neutral-100',
          !show && 'pointer-events-none invisible',
        )}
      >
        <span className="font-extrabold group-hover:text-neutral-0">···</span>
        <div className="absolute right-0 top-full z-50 hidden min-w-full rounded pt-4 shadow-lg animate-in fade-in slide-in-from-top-3 group-hover:block">
          <ul className="flex min-w-52 flex-col bg-neutral-20 pl-4">
            {(() => {
              let index = 0
              return children.map(({ href, name, roles }) => {
                if (!roles.some((role) => availableRoles.includes(role))) {
                  return null
                }
                index++

                if (index <= itemsOnFirstRow) {
                  return null
                }

                const isActive = isLinkActive(href, pathname, isTopLevel)

                return (
                  <li key={href} className="contents">
                    <Link
                      className={cn('relative flex items-center px-4 py-3', {
                        'after:absolute after:inset-x-0 after:left-0 after:my-auto after:h-1/2 after:w-0.5 after:bg-neutral-100':
                          isActive,
                      })}
                      href={href}
                    >
                      <span
                        className={cn('inline-block', {
                          'translate-x-0.5 scale-105 font-medium transition-all ease-out': isActive,
                        })}
                      >
                        {name}
                      </span>
                    </Link>
                  </li>
                )
              })
            })()}
          </ul>
        </div>
      </div>
    </div>
  )
}

const isLinkActive = (href: string, pathname: string, isTopLevel: boolean): boolean => {
  const firstPartOfUrl = href.split('/')[1]
  const cleanHref = `${href.split('?')[0]}/`
  return pathname === (isTopLevel ? `/${firstPartOfUrl}` : cleanHref)
}
