/*
 * Copyright © 2023 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

// swagger-error-diffs: group=BO get /bo/profile
export const GET_PROFILE_ERRORS = [
  // the user must be signed in
  'sign_in_required',
] as const
