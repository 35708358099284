/*
 * Copyright © 2021 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import axios from 'axios'
import qs from 'qs'

import { GMX_API } from '~/config'

const DeprecatedGmxApi = axios.create({
  baseURL: GMX_API,
  paramsSerializer: (params) => qs.stringify(params, { indices: false }),
  withCredentials: true,
})

/**
 * @deprecated Use ~/backend/bo instead
 */
export default DeprecatedGmxApi
