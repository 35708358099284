/*
 * Copyright © 2021 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import { deprecatedGmxApiRequest } from '~/backend/gmx-api/core/request'

import DeprecatedGmxApi from '../GmxApi'
import { GET_PROFILE_ERRORS } from './error-codes/get-profile'
import { validateProfile } from './Profile.validator'

/**
 * Back Office Profile Controller - Get current back-office user's profile
 * https://test.api.gmx.dev/swagger-ui/#/back-office-profile-controller/getProfileUsingGET
 */
export const getProfileRequest = () =>
  deprecatedGmxApiRequest({
    errorCodes: GET_PROFILE_ERRORS,
    request: () => DeprecatedGmxApi.get('/bo/profile'),

    validate: validateProfile,
  })
