/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import NextLink from 'next/link'
import { type ReactNode } from 'react'

/**
 * @deprecated Use typed params instead.
 */
export function deprecated_dont_use_Link({
  children,
  className,
  href,
  rel,
  target,
}: {
  children: ReactNode
  className?: string
  href: string
  rel?: string
  target?: '_self' | '_blank' | '_parent' | '_top'
}) {
  return (
    <NextLink className={className} href={href} prefetch={false} rel={rel} target={target}>
      {children}
    </NextLink>
  )
}
